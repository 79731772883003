// ATTN we use these constants in data dump
const abTests = {
  // CRO tests
  tyHideAddedProduct: 'ty:hideAddedProduct', // CRO-270
  popupHidePopups: 'popup:hidePopups', // CRO-475 B version won (Product managers need to decide which popups to keep to close this test properly and clean code)
  spAlreadyTriedThis: 'sp:alreadyTriedThis', // CRO-574
  mainDeliveryBlock: 'main:deliveryBlock', // CRO-551
  paymentMoreCouponsForPlansSelection: 'payment:moreCouponsForPlansSelection', // CRO-608
  homeCookieBannerRedesignV2: 'home:cookieBannerRedesignV2', // CRO-593
  homeCookieBannerRedesign: 'home:cookieBannerRedesign', // CRO-593
  queueNewOnboarding: 'queue:newOnboarding', // CRO-528
  leadsTouchPointsRedesign: 'leads:touchPointsRedesignV2', // CRO-562
  homeHeroWithSlider: 'home:heroWithSliderAb', // CRO-762
  homeSocialProof: 'home:socialProof', // CRO-606
  addToQueueSubscriptionPopup: 'sitewide:addToQueueSubscriptionPopup', // CRO-646
  showProductsFirstOnScentProfile: 'sp:showProductsFirstScentProfile', // CRO-631
  registerFieldNoScaling: 'register:fieldNoScaling', // CRO-518
  headerTryNowLink: 'header:tryNowLink', // CRO-627
  subscriptionPopupRedesignV2: 'popup:subscriptionPopupRedesign2', // CRO-548
  roundedButtonsPreTest: 'sitewide:roundedButtonsPreTest', // CRO-409
  // PF tests
  constructorRecommendations: 'constructorRecommendations', // PF-2319
  aiSummaryReview: 'aiSummaryReview', // PF-2847
  queueEasyUpgrade: 'queue:easyUpgrade', // PF-3237
  // EP tests
  miniBottlesOnFullSize: '20ml:miniBottlesOnFullSize', // EP-3095
  tyUserFriendlyFlow: 'ty:userFriendlyFlow', // CRO-600
  registrationProminentTimer: 'register:prominentTimer', //CRO-634'
  // RT tests
  queueRedesignAddonsTabs: 'queue:redesignAddonsTabs', // RT-14
  trackingFragranceSet: 'tracking:fragranceSet', // RT-3
  upgradeTouchPointsRedesign: 'upgrade:touchPointsRedesign', //CRO-620
  homeHowItWorksAsHero: 'home:howItWorksAsHero', // CRO-621
} as const

export type AbTestName = typeof abTests[keyof typeof abTests]


export default abTests
